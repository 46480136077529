<template>
  <div class="contact-details" v-if="!loading">
    <div class="section" v-if="!oneOffEvent">
      <h5>Copy and paste details</h5>
      <div class="content align-center">
        <p>
          Copy contact details from another event in this tour or paste this
          event's contact details to another.
        </p>
        <StandardToggleButton
          title="Actions"
          :active="showActions"
          @click="showActions = !showActions"
          class="options-toggle"
        />
        <slide-up-down
          class="full-width"
          :active="showActions && allEventsDetails != null"
          :duration="500"
        >
          <div class="options-container">
            <div class="copy-from">
              <h5>
                Copy contact details from another event in this tour to this
                event
              </h5>
              <div class="select-event">
                <p class="margin-bottom">1. Choose the event to copy from</p>
                <select
                  v-model="eventToCopyFrom"
                  :class="{ placeholder: eventToCopyFrom == null }"
                >
                  <option :value="null">Choose event</option>
                  <option
                    v-for="event in allEventsDetails"
                    :value="event.id"
                    :key="event.id"
                  >
                    {{ event.name }}
                  </option>
                </select>
              </div>
              <div class="paste">
                <p class="margin-bottom">2. Paste to this event</p>
                <StandardSolidButton
                  title="Paste"
                  :fluid="false"
                  :desktopFluidity="true"
                  :disabled="eventToCopyFrom == null"
                  @click="copyFromEvent()"
                />
              </div>
            </div>
            <div class="paste-to">
              <h5>
                Paste this event’s contact details to another event in this
                tour.
              </h5>
              <div class="select-event">
                <p class="margin-bottom">1. Choose the event to paste to</p>
                <select
                  v-model="eventToPasteTo"
                  :class="{ placeholder: eventToPasteTo == null }"
                >
                  <option :value="null">Choose event</option>
                  <option value="ALL">Paste to all events</option>
                  <option
                    v-for="event in allEventsDetails"
                    :value="event.id"
                    :key="event.id"
                  >
                    {{ event.name }}
                  </option>
                </select>
              </div>
              <div class="paste">
                <p class="margin-bottom">2. Paste to event</p>
                <StandardSolidButton
                  title="Paste"
                  :fluid="false"
                  :disabled="eventToPasteTo == null"
                  :desktopFluidity="true"
                  @click="pasteToEvent()"
                />
              </div>
            </div>
          </div>
        </slide-up-down>
      </div>
    </div>

    <div class="section">
      <h5>Tournament Director</h5>
      <div class="content mb-4">
        <DefaultTextInput
          v-model="eventData.tournamentDirectorName"
          title="Tournament Director name"
          placeholder="Add TD name"
          @change="
            saveEventProperty(
              'tournamentDirectorName',
              eventData.tournamentDirectorName
            )
          "
          :maxlength="50"
        />
      </div>
      <div class="content mb-4">
        <DefaultTextInput
          v-model="eventData.email"
          title="Tournament Director email"
          placeholder="Add TD email"
          @change="saveEventProperty('email', eventData.email)"
          :maxlength="50"
        />
      </div>
      <div class="content">
        <DefaultTextInput
          v-model="eventData.phone"
          title="Tournament Director phone"
          placeholder="Add TD phone"
          @change="saveEventProperty('phone', eventData.phone)"
          :maxlength="50"
        />
      </div>
    </div>
    <div class="section">
      <h5>Assistant Tournament Director</h5>

      <div class="content mb-4">
        <DefaultTextInput
          v-model="eventData.assistantTournamentDirectorName"
          title="Assistant Tournament Director name"
          placeholder="Add Assistant TD name"
          @change="
            saveEventProperty(
              'assistantTournamentDirectorName',
              eventData.assistantTournamentDirectorName
            )
          "
          :maxlength="50"
        />
      </div>
      <div class="content mb-4">
        <DefaultTextInput
          v-model="eventData.assistantTournamentDirectorEmail"
          title="Assistant Tournament Director email"
          placeholder="Add Assistant TD email"
          @input="
            saveEventProperty(
              'assistantTournamentDirectorEmail',
              eventData.assistantTournamentDirectorEmail
            )
          "
          :maxlength="50"
        />
      </div>
      <div class="content">
        <DefaultTextInput
          v-model="eventData.assistantTournamentDirectorPhone"
          title="Assistant Tournament Director phone"
          placeholder="Add Assistant TD phone"
          @input="
            saveEventProperty(
              'assistantTournamentDirectorPhone',
              eventData.assistantTournamentDirectorPhone
            )
          "
          :maxlength="50"
        />
      </div>
    </div>
    <div class="section">
      <h5>Event details</h5>
      <div class="content mb-4">
        <DefaultTextInput
          v-model="eventData.website"
          title="Event website"
          placeholder="Add event website"
          @change="saveEventProperty('website', eventData.website)"
          :maxlength="100"
        />
      </div>
      <div class="content mb-4">
        <DefaultTextInput
          v-model="eventData.pdgaEventId"
          title="PDGA event ID"
          placeholder="Paste PDGA event ID"
          @change="saveEventProperty('pdgaEventId', eventData.pdgaEventId)"
          :maxlength="10"
        />
      </div>
      <div class="content mb-4">
        <DefaultTextInput
          v-model="eventData.location"
          title="Event address"
          placeholder="Add event address"
          @change="saveEventProperty('location', eventData.location)"
          :maxlength="100"
        />
      </div>

      <div class="content mb-4 vertical">
        <p class="margin-bottom">Event city & country</p>
        <div class="set-location" v-if="eventData.city != null">
          {{ eventData.city.name + ", " + eventData.city.country }}
          <svg
            @click="saveEventProperty('cityId', null)"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            class="remove-button"
          >
            <g transform="translate(-320 -377)">
              <g transform="translate(320 377)">
                <path class="a" d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z" />
              </g>
              <line class="b" x2="10" transform="translate(327 389)" />
            </g>
          </svg>
        </div>
        <div
          class="find-location"
          v-else
          v-click-outside="clearSearch"
          :class="{ expanded: locationSearchResults.length > 0 }"
        >
          <input
            type="text"
            autocomplete="new-password"
            maxlength="50"
            placeholder="Your city"
            v-if="eventData.city == null"
            v-model="locationSearch"
            @blur="locationSearch = ''"
            @keydown="debouncedCitySearch()"
          />
          <transition-group tag="ul" name="slide-fade">
            <li
              v-for="location in locationSearchResults"
              :key="location.id"
              @click.stop="selectCity(location)"
            >
              {{ location.name }}, {{ location.country }}
            </li>
          </transition-group>
        </div>
      </div>

      <div class="content vertical">
        <p class="margin-bottom">Additional details</p>
        <textarea
          maxlength="1500"
          v-model="eventData.description"
          placeholder="Add additional details"
          @change="saveEventProperty('description', eventData.description)"
        />
      </div>
    </div>
    <div class="section">
      <h5>Payment</h5>
      <div class="content">
        <DefaultTextInput
          v-if="eventData.tour.paymentType == 'MANUAL'"
          v-model="eventData.paymentInfo"
          @change="saveEventProperty('paymentInfo', eventData.paymentInfo)"
          placeholder="Add payment details"
          :maxlength="500"
          title="Payment details"
        />
        <p v-else>
          Since direct payments are enabled on this event the following message
          will show on your public event page:
          <i>
            Direct payments are enabled for this event. Pay with bank/credit
            card upon registration.
          </i>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
import SlideUpDown from "vue-slide-up-down";
import ClickOutside from "vue-click-outside";
import DefaultTextInput from "@/components/UIElements/DefaultTextInput";
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";
import StandardToggleButton from "@/components/UIElements/StandardToggleButton";

export default {
  name: "EventContactDetails",
  components: {
    DefaultTextInput,
    StandardSolidButton,
    StandardToggleButton,
    SlideUpDown,
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      eventData: {},
      showStatusMessage: false,
      statusMessageValue: true,
      loading: true,
      locationSearch: "",
      locationSearchResults: [],
      city: {},
      eventToPasteTo: null,
      eventToCopyFrom: null,
      showActions: false,
      allEventsDetails: null,
    };
  },
  watch: {
    $route: "loadData",
    showActions: "loadEventDetails",
  },
  computed: {
    managedEvent() {
      return this.$store.getters.managedEvent.data;
    },
    oneOffEvent() {
      return this.managedEvent.tour.events.length == 1 ? true : false;
    },
  },
  methods: {
    async loadEventDetails() {
      if (this.allEventsDetails == null) {
        try {
          const allEventsDetails = await this.$axios({
            headers: {
              Authorization: this.$store.state.user.sessionInfo.token,
            },
            data: {
              query: `
              {
                event(eventId:"${this.$router.currentRoute.params.eventId}"){
                  tour{
                    paymentType
                    events{
                      id
                      name
                      description
                      tournamentDirectorName
                      phone
                      email
                      location
                      paymentInfo
                      website
                      assistantTournamentDirectorName
                      assistantTournamentDirectorPhone
                      assistantTournamentDirectorEmail
                      city{
                        id
                      }              
                    }
                  }
                }
              }
            `,
            },
          });

          this.allEventsDetails = allEventsDetails.data.data.event.tour.events;
        } catch (err) {
          console.log(err);
        }
      }
    },
    async copyFromEvent() {
      const eventDetails = this.allEventsDetails.find(
        (event) => event.id == this.eventToCopyFrom
      );

      const detailsToUpdate = {
        cityId: eventDetails.city ? eventDetails.city.id : null,
        description: eventDetails.description,
        email: eventDetails.email,
        phone: eventDetails.phone,
        paymentInfo: eventDetails.paymentInfo,
        tournamentDirectorName: eventDetails.tournamentDirectorName,
        assistantTournamentDirectorName:
          eventDetails.assistantTournamentDirectorName,
        assistantTournamentDirectorEmail:
          eventDetails.assistantTournamentDirectorEmail,
        assistantTournamentDirectorPhone:
          eventDetails.assistantTournamentDirectorPhone,
        website: eventDetails.website,
        location: eventDetails.location,
      };

      try {
        const updatedEvent = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          mutation UpdateEvent($UpdateEvent: UpdateEventInput!){
            UpdateEvent(eventId:"${this.$router.currentRoute.params.eventId}", input:$UpdateEvent){
              id
            }
          }
          `,
            variables: {
              UpdateEvent: detailsToUpdate,
            },
          },
        });

        this.loadData();
        this.showActions = false;
        this.eventToCopyFrom = null;

        this.$store.dispatch("showMessage", {
          show: true,
          message: "Details pasted",
          type: "success",
        });
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Unable to copy",
          type: "error",
        });
      }
    },
    async pasteToEvent() {
      const eventDetails = this.allEventsDetails.find(
        (event) => event.id == this.$router.currentRoute.params.eventId
      );

      const detailsToUpdate = {
        cityId: eventDetails.city ? eventDetails.city.id : null,
        description: eventDetails.description,
        email: eventDetails.email,
        phone: eventDetails.phone,
        paymentInfo: eventDetails.paymentInfo,
        tournamentDirectorName: eventDetails.tournamentDirectorName,
        assistantTournamentDirectorName:
          eventDetails.assistantTournamentDirectorName,
        assistantTournamentDirectorEmail:
          eventDetails.assistantTournamentDirectorEmail,
        assistantTournamentDirectorPhone:
          eventDetails.assistantTournamentDirectorPhone,
        website: eventDetails.website,
        location: eventDetails.location,
      };

      try {
        if (this.eventToPasteTo == "ALL") {
          for (let index = 0; index < this.allEventsDetails.length; index++) {
            await this.$axios({
              headers: {
                Authorization: this.$store.state.user.sessionInfo.token,
              },
              data: {
                query: `
              mutation UpdateEvent($UpdateEvent: UpdateEventInput!){
                UpdateEvent(eventId:"${this.allEventsDetails[index].id}", input:$UpdateEvent){
                  id
                }
              }
              `,
                variables: {
                  UpdateEvent: detailsToUpdate,
                },
              },
            });
          }
        } else {
          await this.$axios({
            headers: {
              Authorization: this.$store.state.user.sessionInfo.token,
            },
            data: {
              query: `
              mutation UpdateEvent($UpdateEvent: UpdateEventInput!){
                UpdateEvent(eventId:"${this.eventToPasteTo}", input:$UpdateEvent){
                  id
                }
              }
              `,
              variables: {
                UpdateEvent: detailsToUpdate,
              },
            },
          });
        }

        this.loadData();
        this.showActions = false;
        this.eventToPasteTo = null;

        this.$store.dispatch("showMessage", {
          show: true,
          message: "Details pasted",
          type: "success",
        });
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Unable to copy",
          type: "error",
        });
      }
    },
    clearSearch() {
      this.locationSearch = "";
      this.locationSearchResults = [];
    },
    closeLocationPicker() {
      this.locationSearchResults = [];
    },
    selectCity(location) {
      this.saveEventProperty("cityId", location.id);
      this.locationSearch = "";
      this.locationSearchResults = [];
    },
    debouncedCitySearch: debounce(
      function () {
        this.citySearch();
      },
      1000,
      { maxWait: 1200 }
    ),
    citySearch() {
      this.$axios({
        data: {
          query: `
          {
            citySearch(query:"${this.locationSearch}"){
              id
              name
              country
              county
              geolocation{
                lng
                lat
              }
            }
          }
          `,
        },
      })
        .then((result) => {
          if (this.locationSearch != "") {
            this.locationSearchResults = result.data.data.citySearch;
          }
        })
        .catch(() => {});
    },
    saveEventProperty(property, value) {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation UpdateEvent($UpdateEvent: UpdateEventInput!){
            UpdateEvent(eventId:"${this.$router.currentRoute.params.eventId}", input:$UpdateEvent){
              id
            }
          }
          `,
          variables: {
            UpdateEvent: {
              [property]: value,
            },
          },
        },
      })
        .then(() => {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Saved",
            type: "success",
          });

          this.loadData();
          this.$parent.loadData();
        })
        .catch(() => {
          this.showStatusMessage = true;
          this.statusMessageValue = false;

          var self = this;
          setTimeout(function () {
            self.showStatusMessage = false;
          }, 1200);
        });
    },
    saveUpdates() {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation UpdateEvent($UpdateEvent: UpdateEventInput!){
            UpdateEvent(eventId:"${this.$router.currentRoute.params.eventId}", input:$UpdateEvent){
              id
            }
          }
          `,
          variables: {
            UpdateEvent: {
              description: this.eventData.description,
              tournamentDirectorName: this.eventData.tournamentDirectorName,
              assistantTournamentDirectorName:
                this.eventData.assistantTournamentDirectorName,
              phone: this.eventData.phone,
              email: this.eventData.email,
              location: this.eventData.location,
              website: this.eventData.website,
            },
          },
        },
      })
        .then(() => {
          this.showStatusMessage = true;

          var self = this;
          setTimeout(function () {
            self.showStatusMessage = false;
          }, 1200);

          this.loadData();
        })
        .catch(() => {
          this.showStatusMessage = true;
          this.statusMessageValue = false;

          var self = this;
          setTimeout(function () {
            self.showStatusMessage = false;
          }, 1200);
        });
    },
    async loadData() {
      await this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          {
            event(eventId:"${this.$router.currentRoute.params.eventId}"){
              tour{
                paymentType
              }
              eventMessages{
                id
                eventId
                subject
                message
                audience
                createdAt
                updatedAt
                updatedBy{
                  id
                  firstName
                  lastName
                }
                author{
                  id
                  firstName
                  lastName
                  email
                }
              }
              paymentInfo
              description
              tournamentDirectorName
              assistantTournamentDirectorName
              assistantTournamentDirectorEmail
              assistantTournamentDirectorPhone
              pdgaEventId
              phone
              email
              location
              website
              city{
                id
                name
                country
                geolocation{
                  lng
                  lat
                }
              }
            }
          }
          `,
        },
      })
        .then((result) => {
          this.eventData = result.data.data.event;
          this.loading = false;
        })
        .catch(() => {});
    },
  },
  mounted() {
    this.loadData();
  },
  beforeCreate() {},
  destroyed() {},
};
</script>


<style scoped lang="scss" >
@import "@/styles/Typography";

h5 {
  margin-bottom: 18px;
}

.options-toggle {
  margin-top: 32px;
  margin-left: auto;
}

.options-container {
  margin: 24px 0px 0px 0px;
  background: white;
  border-radius: 6px;
  border: 2px solid $ocean;
  padding: 20px;
  p {
    font-size: 16px;
  }

  select {
    &.placeholder {
      color: $blizzard;

      option {
        color: $midnight;
      }
    }
  }

  .paste-to {
    margin-top: 32px;
    padding-top: 24px;
    border-top: 1px solid $fog;
  }
}
.contact-details {
  .section {
    padding: 20px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 0 3px $sleet;
    margin: 0 12px 24px 12px;

    h5 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include Gilroy-Bold;
    }

    .section-header {
      display: flex;
      justify-content: space-between;

      h5,
      p {
        margin-bottom: 0;
      }

      p {
        color: $twilight;
        cursor: pointer;
      }
    }

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      &.align-center {
        align-items: center;
      }
      .margin-bottom {
        margin-bottom: 10px;
      }

      .set-location {
        border: 1px solid $blizzard;
        border-radius: 6px;
        height: 48px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15px;
        @include Gilroy-Bold;
        transition: all ease 0.3s;

        &:hover {
          border: 1px solid $midnight;
        }

        .remove-button {
          cursor: pointer;
          .a {
            fill: $dusk;
          }
          .b {
            fill: none;
            stroke: #fff;
            stroke-width: 2px;
          }
        }
      }
      .find-location {
        width: 100%;
        border: 1px solid $blizzard;
        border-radius: 6px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        padding: 0 15px;
        @include Gilroy-Bold;
        transition: all ease 0.3s;

        &.expanded {
          padding-bottom: 20px;
          transition: all ease 0.3s;
        }

        &:hover {
          border: 1px solid $midnight;
        }

        input {
          height: 46px;
          border: none;
          width: 100%;
          outline: none;
          transition: all ease 0.3s;

          &:focus {
            border: 0px solid transparent;
          }

          &::placeholder {
            @include Gilroy-Regular;
            color: $strom;
          }
        }

        .slide-fade-enter-active {
          transition: all 0.3s ease;
        }
        .slide-fade-leave-active {
          transition: all 0.3s ease;
        }
        .slide-fade-enter,
        .slide-fade-leave-to {
          transition: all 0.3s ease;
          max-height: 0;
          padding: 0px 15px;
        }

        ul {
          margin: 0;
          padding: 0;
          overflow: hidden;
          transition: all ease 0.3s;
          width: 100%;
          li {
            cursor: pointer;
            transition: all ease 0.3s;
            max-height: 50px;
            overflow: hidden;
            padding: 5px 15px;
            transition: all ease 0.3s;
            width: 100%;
            display: flex;

            &:hover {
              background-color: $mist;
              transition: all ease 0.3s;
            }
          }
        }
      }

      textarea {
        width: 100%;
        height: 500px;
        border: 1px solid $blizzard;
        transition: all ease 0.3s;
        border-radius: 6px;
        padding: 15px;

        &::placeholder {
          color: $strom;
        }

        &:hover {
          border: 1px solid $midnight;
        }
      }

      &.vertical {
        flex-direction: column;
      }

      p {
        margin: 0;
        width: 100%;
      }
    }
  }
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .options-toggle {
    margin-top: 0;
  }
  .full-width {
    width: 100%;
  }
  .options-container {
    padding: 30px 30px 40px 30px;
  }

  .select-event,
  .paste {
    width: calc(50% - 40px);
    margin: 0 20px;
  }

  .copy-from,
  .paste-to {
    display: flex;
    flex-wrap: wrap;
  }

  h5 {
    margin-bottom: 24px;
    width: 100%;
  }
  .contact-details {
    .section {
      padding: 30px 30px 40px 30px;
      border-radius: 10px;
      margin: 0 0 32px 0;
      .content {
        margin: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        p {
          width: 75%;
        }

        b {
          display: inline;
        }

        .find-location {
          width: calc(50% - 20px);
        }
        .set-location {
          width: calc(50% - 20px);
        }
        textarea {
          width: calc(50% - 20px);
          height: 300px;
        }
      }
    }
  }
}
</style>
