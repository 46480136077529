<template>
  <button
    @click.prevent="$emit('click')"
    class="standard-toggle-button"
    :class="{ active }"
  >{{ title }}</button>
</template>

<script>
export default {
  props: ["title", "active"],
  name: "StandardToggleButton",
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() { },
  beforeCreate() { },
  destroyed() { },
};
</script>

<style lang="scss" scoped>
.standard-toggle-button {
  font-size: 14px;
  @include Gilroy-Bold;
  background: $cloud;
  color: $midnight;
  border-radius: 6px;
  border: none;
  padding: 0px 12px;
  transition: all ease 0.3s;
  height: 32px;

  &:hover {
    background: $fog;
  }

  &.active {
    background: $ocean;
    color: white;

    &:hover {
      background: $oceanshade;
    }
  }
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .standard-toggle-button {
    height: 40px;
    padding: 0 18px;
    font-size: 16px;
  }
}
</style>
